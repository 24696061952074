import "../../CSS/receivePopup.css";
import "../../CSS/receivePopup_mobile.css";
const ReceivePopup = () => {
  return (
    <div className="receivePopup">
      <img src="/images/main/popup.jpg" alt="" />
    </div>
  );
};

export default ReceivePopup;
